.continent {
  cursor: pointer;
}

.map-container path {
  stroke: var(--primary);
}

.--continent-hover path {
  fill: var(--primary);
  stroke: var(--white);
}

.map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 12px;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.--map-fade {
  animation: 0.5s fade-out forwards;
}

@keyframes move-in {
  from {
    opacity: 0;
    bottom: -100px;
  }

  to {
    opacity: 1;
    bottom: 36px;
  }
}

.map-prompt-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 90%;
  box-sizing: border-box;
  padding: 6px;
}

.map-prompt {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 2px 24px 0 rgb(12 12 12 / 16%);
  animation: 1s 0.5s move-in forwards;
  opacity: 0;
}

.map-prompt p {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}


@media (min-width: 768px) {
  .map-prompt p {
    font-size: 28px;
  }
}

.map-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
}

@keyframes scale-in {
  from {
    transform: scale(1.5);
  }

  to {
    transform: scale(1.1);
  }
}

.map-background video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1.5);
  animation: scale-in 4s forwards;
}
