.timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  z-index: 10;
}

.timeline-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
  margin-right: 12px;
  border-right: 2px solid var(--white);
}

.timeline-controls button {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border: none;
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--black);
  padding: 6px;
}

.timeline-controls button path {
  fill: var(--white);
}

.timeline-controls button:first-of-type {
  margin-right: 8px;
}

.timeline-indicator {
  display: block;
  height: 36px;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: var(--dark-gray);
  opacity: 0.5;
}

.timeline-indicator.--is-active {
  opacity: 1;
  background-color: var(--white);
}
