.journey {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  background-color: var(--clouds);
  overflow: hidden;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scale-in {
  from {
    transform: scale(1.5);
  }

  to {
    transform: scale(1.1);
  }
}

.journey-background {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  animation: 0.5s fade-in forwards, 5s scale-in forwards;
  transform: scale(1.1);
  opacity: 0;
}

.journey-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.85));
  z-index: 1;
}

@media (min-width: 768px) {
  .journey-overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.journey.--journey-fade-out .journey-background {
  animation: 1s fade-out forwards;
  transform: scale(1.1);
}

.journey.--journey-fade-out .journey-content,
.journey.--journey-fade-out .journey-audio {
  animation: 1s fade-out forwards;
}

.journey .timeline {
  margin-top: auto;
}

.journey-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;

  width: 100%;
  max-width: 700px;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;

  opacity: 0;
  animation: 0.5s 0.5s fade-in forwards;
}

.story-title {
  font-family: courier-std, monospace;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.1;
  color: var(--white);
}

.story-byline {
  font-family: courier-std, monospace;
  font-weight: 600;
  font-size: 14px;
  color: var(--white);
  width: 100%;
  margin-top: 12px;
  margin-bottom: 24px;
}

.story-quote {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: var(--white);
  margin: 0;
  padding: 12px 16px;
  padding-bottom: 0;

  width: 100%;
  max-width: 650px;

  transition: transform 0.5s;
}

.story-quote:hover {
  transform: scale(1.05);
}

.story-link {
  font-family: courier-std, monospace;
  font-weight: 600;
  font-size: 14px;
  color: var(--white);
  margin-top: 24px;
}

.story-link:hover {
  color: var(--primary);
}

.story-summary {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.story-summary-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.story-summary h3 {
  font-family: proxima-nova-extra-condensed, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--white);
  margin-bottom: 12px;
}

.story-summary li {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--white);
  margin: 0;
  margin-bottom: 12px;
  margin-left: 32px;
}

.story-summary li:nth-of-type(n+2) {
  display: none;
}

.journey-globe {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

@media (min-width: 414px) {
  .story-title {
    font-size: 28px;
  }

  .story-byline {
    font-size: 16px;
  }

  .story-quote {
    font-size: 24px;
  }

  .story-link {
    font-size: 16px;
  }

  .story-summary h3 {
    font-size: 24px;
  }

  .story-summary li {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .story-title {
    font-size: 36px;
  }

  .story-byline {
    font-size: 18px;
  }

  .story-quote {
    font-size: 28px;
  }

  .story-link {
    font-size: 18px;
    margin-top: 32px;
  }

  .story-summary h3 {
    font-size: 28px;
  }

  .story-summary li {
    font-size: 18px;
  }

  .story-summary li:nth-of-type(n+2) {
    display: list-item;
  }
}
