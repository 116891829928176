.app {
  position: relative;
  overflow: hidden;
}

.logo {
  width: 64px;
}

.logo-container {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 100;
}

.logo-container:after {
  content: '';
  display: block;
  width: 72px;
  height: 72px;
  background-color: #FFF;
  border-radius: 50%;
  position: absolute;
  top: -16px;
  left: -4px;
  z-index: -1;
}
