@keyframes slide-in {
  from {
    transform: translate(500px);
  }

  to {
    transform: translate(0);
  }
}

.story-audio {
  position: absolute;
  bottom: 72px;
  right: 24px;
  z-index: 10;

  animation: slide-in 1.5s forwards;
}

.story-audio-layout {
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: var(--white);
  border-radius: 4px;
}

.story-audio button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;

  margin: 0;
  padding: 0;
  margin-right: 12px;
}

.story-audio button path {
  fill: var(--primary);
}
